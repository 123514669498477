import React, {ChangeEvent, memo, useCallback, useState} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Insets from './Insets';
import FixedLayout
  from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Input from '@vkontakte/vkui/dist/components/Input/Input';
import Div from '../../atomic/Div';

import useSelector from '../../../hooks/useSelector';
import {getFieldTitle} from '../../../utils/enums';

import imageUrl from '../../../assets/register-1.base64.svg';

import {FIELD} from 'bridge';
import {UserRegistrationInfo} from './types';
import { FormLayoutGroup, Select } from '@vkontakte/vkui'

interface Props {
  onCompleted(user: UserRegistrationInfo): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    paddingTop: 50,
    paddingBottom: 60,
    '& .FormLayout__row': {
      margin: 0,
      padding: 0,
    },
    '& .FormLayout__row .FormField': {
      margin: 0,
    },
    '& .FormField__row': {
      margin: 0,
      padding: 0,
    }
  },
  top: {
    background: `url(${imageUrl}) no-repeat center bottom`,
    backgroundSize: '100% auto',
    paddingBottom: '67.47%',
  },
  title: {
    fontFamily: theme.typography.fontFamilyTT,
    fontSize: 28,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    lineHeight: '28px',
    marginBottom: 20,
    color: '#0177FD',
  },
  fieldset: {
    padding: '12px 16px',
  },
  label: {
    display: 'block',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: -0.154,
    color: '#6F7985',
    marginBottom: '8px',
  },
  help: {
    textAlign: 'center',
  },
  form: {
    paddingTop: 13,
    paddingBottom: `calc(62px + var(--safe-area-inset-bottom))`,
  },
  input: {
  },
  fixed: {
    background: 'linear-gradient(to top, white 0%, #ffffffd9 20%, ' +
      'transparent 100%)',
    paddingTop: 10,
    paddingBottom: 18,
  },
  radio: {
    [theme.breakpoints.up(320)]: {
      paddingLeft: 25,
      paddingRight: 25,
    },
  },
}));

/**
 * Вью регистрации пользователя
 * @type {React.NamedExoticComponent<object>}
 */
const UserView = memo((props: Props) => {
  const {onCompleted} = props;
  const mc = useStyles(props);
  const {fullName} = useSelector(state => ({
    fullName: state.user.fullName,
  }));
  const [name, setName] = useState(fullName);
  const [field, setField] = useState(FIELD.ANDROID);
  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);
  const filteredName = name
    .replace(/^[^A-zА-я0-9-_.]+/g, '')
    .replace(/[^A-zА-я0-9-_.]+$/g, '')
  const nameFilled = filteredName.length > 0;
  const errored = filteredName.length < 3 || filteredName.length > 32;
  const completeEnabled = nameFilled && !errored;

  const onButtonClick = useCallback(() => {
    onCompleted({name, field});
  }, [name, field, onCompleted]);

  const onFieldChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    setField(event.target.value);
  }, []);

  console.log(Object.values(FIELD))

  return (
    <Insets className={mc.root} bottom={true}>
      <div className={mc.title}>Расскажите о себе</div>
      <div className={mc.top}/>
      <FormLayout className={mc.form}>
        <div className={mc.fieldset}>
          <label htmlFor={'input_name'} className={mc.label}>Укажите свои имя и фамилию</label>
          <Input
            id={'input_name'}
            className={mc.input}
            value={name}
            onChange={onNameChange}
            status={nameFilled && errored ? 'error' : 'default'}
            placeholder={'Иван Иванов'}
            bottom={
              nameFilled
                ? errored &&
                <div className={mc.help}>
                  Длина имени должна быть в пределах от 3 до 32 символов
                </div>
                : <div className={mc.help}>Укажите свои имя и фамилию</div>
            }
          />
        </div>
        <div className={mc.fieldset}>
          <label htmlFor={'input_field'} className={mc.label}>Выберите специализацию</label>
          <Select
            id={'input_field'}
            className={mc.input}
            value={field}
            onChange={onFieldChange}
          >
            {Object.values(FIELD).map(f => (
              <option key={f} value={f}>{getFieldTitle(f)}</option>
            ))}
          </Select>
        </div>
        {/*<Input
          className={mc.input}
          value={getFieldTitle(field)}
          disabled={true}
        />
        <FormLayoutGroup>
          {Object.values(FIELD).map(f => (
            <Radio
              className={mc.radio}
              checked={field === f}
              onChange={() => setField(f)}
              key={f}
            >
              {getFieldTitle(f)}
            </Radio>
          ))}
        </FormLayoutGroup>*/}
      </FormLayout>
      <FixedLayout vertical={'bottom'}>
        <Div className={mc.fixed}>
          <Button
            size={'xl'}
            onClick={onButtonClick}
            disabled={!completeEnabled}
          >
            Далее
          </Button>
        </Div>
      </FixedLayout>
    </Insets>
  );
});

export default UserView;

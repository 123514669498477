import {ComponentType, SVGProps} from 'react';

/**
 * Тип для svg
 */
export type SVGComponentType = ComponentType<SVGProps<SVGSVGElement>>;

/**
 * Список возможных поломок авто
 */
export enum CarBreak {
  EngineBurn,
}

/**
 * Мап который описывает визуальные настройки для поломок
 */
export type BreakComponents = {
  [Break in CarBreak]: SVGComponentType;
};

import React, {memo, useCallback, useMemo, useState} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';

import {COLOR} from 'bridge';
import {getColorTitle} from '../utils/enums';
import Div from './atomic/Div';
import {Theme} from '../theme/types';

interface Props {
  controlled?: boolean;
  color: COLOR;
  title: string;
  onChange(color: COLOR): any;
  onSave?(color: COLOR): any;
}

const getColor = (c: COLOR): string => {
  const colors = {
    [COLOR.CYAN]: '#00DBF0',
    [COLOR.BLUE]: '#218AFF',
    [COLOR.PINK]: '#FF529E',
    [COLOR.PURPLE]: '#AD21ED',
    [COLOR.GREEN]: '#00F2AD',
  }
  return colors[c]
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: '12px',
  },
  title: {
    padding: '0 4px',
    color: '#6F7985',
    fontSize: 15,
    lineHeight: '22px',
    letterSpacing: -0.4,
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
  },
  titleSeparator: {
    borderTop: '0.5px solid rgba(0, 0, 0, 0.12)',
    flex: '1 0 auto',
    margin: '0 6px',
  },
  action: {
    color: '#818C99',
    fontSize: 10,
    lineHeight: '18px',
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionEditing: {
    color: theme.palette.primary.main,
  },
  grid: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  gridItem: {
    width: 64,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:not(:last-child)': {
    }
  },
  radio: {
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
  },
  circle: {
    position: 'relative',
    width: 44,
    height: 44,
    borderRadius: '50%',
    marginBottom: 4,
  },
  circleSelector: {
    position: 'absolute',
    top: 2,
    left: 2,
    width: 40,
    height: 40,
    border: '2px solid white',
    borderRadius: '50%',
    backgroundImage: `url(${require('../assets/color-selector.svg')})`,
    backgroundPosition: 'center center',
    backgroundSize: '24px 24px',
    backgroundRepeat: 'no-repeat',
    opacity: 0,
    transition: 'opacity 0.3s'
  },
  circleSelectorChecked: {
    opacity: 1,
  },
  colorName: {
    color: '#818C99',
    maxWidth: '100%',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
  },
  colorNameChecked: {
    color: 'black',
  },
}));

/**
 * Предоставляет возможность выбирать цвет
 * @type {React.NamedExoticComponent<Props>}
 */
const ColorSelector = memo((props: Props) => {
  const {onSave, onChange, color, title} = props;
  const mc = useStyles(props);
  const colorsSorted = useMemo<COLOR[]>(() => {
    return Object
      .values(COLOR)
      // .sort((a, b) => getColorTitle(a).length - getColorTitle(b).length)
  }, []);

  const onClick = (c: COLOR) => {
    onChange(c)
    if (onSave) {
      onSave(c)
    }
  }

  return (
    <Div className={mc.root}>
      <div className={mc.title}>
        {title}
      </div>
      <div className={mc.grid}>
        {colorsSorted.map(item => (
          <div className={mc.gridItem} key={item} onClick={() => onClick(item)}>
            <div
              className={c([mc.circle, {[mc.circleChecked]: color === item}])}
              style={{ backgroundColor: getColor(item) }}
            >
              <div className={
                c([
                  mc.circleSelector,
                  {[mc.circleSelectorChecked]: color === item}
                ])
              }/>
            </div>
            <div
              className={
                c([mc.colorName, {[mc.colorNameChecked]: color === item}])
              }
            >{getColorTitle(item)}</div>
          </div>
        ))}
      </div>
    </Div>
  );
});

export default ColorSelector;

import styled from '@material-ui/styles/styled/styled';

import {BreakComponents, CarBreak} from './types';

import {ReactComponent as SVGCarEngineBurned} from '../../assets/car-engine-burned.svg';

const breakComponents: BreakComponents = {
  [CarBreak.EngineBurn]: styled(SVGCarEngineBurned)({
    position: 'absolute',
    left: 11,
    top: -7,
    zIndex: -1,
  }),
};

export default breakComponents;

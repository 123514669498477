import React, {
  memo,
  ReactElement,
  useMemo,
} from 'react';

import EpicContextProvider from './EpicContextProvider';

import {EpicViewProps} from './EpicView';
import {BottomNavigationProps} from '../BottomNavigation/BottomNavigation';

interface Props {
  activeView: string | null;
  children: ReactElement<EpicViewProps> | ReactElement<EpicViewProps>[];
  navigation?: ReactElement<BottomNavigationProps> | null;
}

/**
 * Компонент который контролирует текущее вью. Его особенностью является
 * способность кэширования вью.
 * @type {React.NamedExoticComponent<Props>}
 */
const Epic = memo((props: Props) => {
  const {activeView, children, navigation} = props;
  const context = useMemo(() => ({
    activeView,
  }), [activeView]);

  return (
    <EpicContextProvider value={context}>
      {children}
      {navigation}
    </EpicContextProvider>
  );
});

export default Epic;

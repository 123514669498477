import bridge from '@vkontakte/vk-bridge';
import storyBackImageUrl from '../assets/story-back.base64.svg';

import button from '../assets/Button.png'

import {formatTime} from './misc';

type ShareResultsOptions = {
  time: number;
  appId: number;
} & ({ type: 'common' } | { type: 'semi-final' | 'final'; placement: number });

/**
 * Creates data url with passed fill
 * @param {number} width
 * @param {number} height
 * @param fill
 * @returns {string | null}
 */
function createRectangleDataUrl(
  width: number,
  height: number,
  fill?: string,
): string {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return '';
  }

  canvas.height = height;
  canvas.width = width;

  if (fill) {
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.fillStyle = fill;
    ctx.fill();
  }

  return canvas.toDataURL();
}

/**
 * Shares race results
 * @returns {Promise<string extends AnyReceiveMethodName ? ReceiveData<string> : void>}
 * @param options
 */
export async function shareResults(options: ShareResultsOptions) {
  const {appId, time} = options;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  const image = await new Promise<HTMLImageElement>((res, rej) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onstalled = rej;
    image.onerror = rej;
    image.onload = () => res(image);
    image.src = storyBackImageUrl;
  });

  const dpiScale = 2;
  const imageWidth = image.width * dpiScale;
  const imageHeight = image.height * dpiScale;
  canvas.width = imageWidth;

  const fullHeight = 1.78151 * imageWidth
  const verticalMargin = (fullHeight - imageHeight) / 2
  canvas.height = fullHeight;
  ctx.fillStyle = '#FFFFFF';
  ctx.rect(0, 0, imageWidth, fullHeight)
  ctx.fill();
  // Draw story image
  // eslint-disable-next-line max-len
  ctx.drawImage(image, 0, 0, image.width, image.height - 80, 0, verticalMargin, imageWidth, imageHeight - 80 * dpiScale);

  // Draw results
  ctx.textAlign = 'center';
  ctx.textBaseline = 'top';
  ctx.fillStyle = '#4986CC';

  // In case, it is common race, draw time only
  if (options.type === 'common') {
    ctx.font = `bold ${34 * dpiScale}px "TT Commons"`;
    ctx.fillText(formatTime(time), canvas.width / 2, 175 * dpiScale + verticalMargin);
  }
  // Otherwise, we should display placement, race type and time
  else {
    const {placement, type} = options;
    const lines = [
      `${placement} место`,
      `в ${type === 'semi-final' ? 'полуфинале' : 'финале'}`,
      `${formatTime(time)} время`,
    ];
    const textYStart = 175 * dpiScale;
    const lineHeight = 25 * dpiScale;
    ctx.font = `bold ${dpiScale * 22}px "TT Commons"`;

    lines.forEach((line, idx) => {
      // eslint-disable-next-line max-len
      ctx.fillText(line, canvas.width / 2, textYStart + idx * lineHeight + verticalMargin);
    });
  }

  // const clickableXLeft = 24 * dpiScale;
  // const clickableYTop = 338 * dpiScale;
  // const clickableXRight = 351 * dpiScale;
  // const clickableYBottom = 362 * dpiScale;
  const clickableXLeft = 0;
  const clickableYTop = 0;
  const clickableXRight = 328 * dpiScale;
  const clickableYBottom = 44 * dpiScale;

  const blob = createRectangleDataUrl(canvas.width, canvas.height, 'white')
  const stickerBlob = canvas.toDataURL()

  // console.log('blob', blob, canvas.toDataURL())

  console.log('sended', bridge, blob.substr(0, 50), blob.length)

  console.log(blob, stickerBlob, canvas.width, canvas.height, appId, {
      clickableXLeft,
      clickableYTop,
      clickableXRight,
      clickableYBottom,
  })

  /*const buttonImage = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(button);
    reader.onload = function () {
      resolve(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  })*/

  console.log(button)

  const result = await bridge.send('VKWebAppShowStoryBox', {
    background_type: 'image',
    blob: stickerBlob,
    locked: true,
    stickers: [{
      sticker_type: 'renderable',
      sticker: {
        content_type: 'image',
        blob: button,
        /*transform: {
          relation_width: 1,
        },*/
        clickable_zones: [{
          action_type: 'link',
          action: {
            link: `https://vk.com/app${appId}`,
            // tooltip_text_key: 'tooltip_open_app',
            tooltip_text_key: 'tooltip_open_page',
          },
          clickable_area: [
            {x: clickableXLeft, y: clickableYTop},
            {x: clickableXRight, y: clickableYTop},
            {x: clickableXRight, y: clickableYBottom},
            {x: clickableXLeft, y: clickableYBottom},
          ],
        }],
        original_width: 328 * dpiScale,
        original_height: 44 * dpiScale,
        can_delete: false,
      },
    }],
  });

  console.log('result', result)

  return result;
}

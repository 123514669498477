import React, {memo, ReactNode, ReactNodeArray} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../theme/types';

import useInsets from '../../hooks/useInsets';

import {Insets} from '@vkontakte/vk-bridge';

export interface BottomNavigationProps {
  children?: ReactNode | ReactNodeArray;
}

const useStyles = makeStyles<Theme, Insets>(() => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    paddingBottom: ({bottom}) => bottom,
    backgroundColor: 'white',
    zIndex: 1000,
  },
  inner: {
    height: 49,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

/**
 * Навигационная панель внизу экрана
 * @type {React.NamedExoticComponent<BottomNavigationProps>}
 */
const BottomNavigation = memo((props: BottomNavigationProps) => {
  const {children} = props;
  const mc = useStyles(useInsets());

  return (
    <div className={mc.root}>
      <div className={mc.inner}>
        {children}
      </div>
    </div>
  );
});

export default BottomNavigation;

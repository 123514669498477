import React, {memo, useMemo, useState} from 'react';

import GameView from './views/GameView';
import ProfileView from './views/ProfileView';
import RatingView from './views/RatingView';
import FAQView from './views/FAQView';
import Epic, {EpicView} from './Epic';
import BottomNavigation, {BottomNavigationItem} from './BottomNavigation';
// @ts-ignore
import GameOutline from '@vkontakte/icons/dist/28/game_outline';
// @ts-ignore
import UserOutline from '@vkontakte/icons/dist/28/user_outline';
// @ts-ignore
import FavoriteOutline from '@vkontakte/icons/dist/28/favorite_outline';
// @ts-ignore
import HelpOutline from '@vkontakte/icons/dist/28/help_outline';
import useSelector from '../hooks/useSelector';
import makeStyles from '@material-ui/styles/makeStyles'
import { Theme } from '../theme/types'

enum View {
  Game = 'game',
  Profile = 'profile',
  Rating = 'rating',
  FAQ = 'faq'
}

/*const useStyles = makeStyles<Theme>(
  (theme) => ({
    item: {
    }
  })
);*/

const Views = memo(() => {
  //const mc = useStyles();
  const [activeView, setActiveView] = useState(View.Game);
  const navigationItems = useMemo(() => [
    {Icon: GameOutline, title: 'Игра', id: View.Game},
    {Icon: UserOutline, title: 'Профиль', id: View.Profile},
    {Icon: FavoriteOutline, title: 'Рейтинг', id: View.Rating},
    {Icon: HelpOutline, title: 'FAQ', id: View.FAQ},
  ], []);
  const views = useMemo(() => [
    {id: View.Game, Component: GameView},
    {id: View.Profile, Component: ProfileView},
    {id: View.FAQ, Component: FAQView},
    {id: View.Rating, Component: RatingView},
  ], []);
  const isNavigationShown = useSelector(state => {
    return state.layout.isNavigationShown;
  });

  return (
    <Epic
      activeView={activeView}
      navigation={
        isNavigationShown ? (
          <BottomNavigation>
            {navigationItems.map(({Icon, title, id}) => (
              <BottomNavigationItem
                // className={mc.item}
                Icon={Icon}
                title={title}
                active={id === activeView}
                onClick={() => setActiveView(id)}
                key={id}
              />
            ))}
          </BottomNavigation>
        ) : null
      }
    >
      {views.map(({id, Component}) => (
        <EpicView id={id} key={id}>
          <Component/>
        </EpicView>
      ))}
    </Epic>
  );
});

export default Views;

import React, {ComponentType, HTMLProps, memo} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../theme/types';

interface Props extends HTMLProps<HTMLDivElement> {
  Icon: ComponentType<{ className: string }>;
  title: string;
  active?: boolean;
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    flex: '1 0 0',
  },
  icon: {
    width: 28,
    height: 28,
    color: '#DADADA',
    margin: '0 auto 2px',
  },
  iconActive: {
    color: theme.palette.primary.main,
  },
  title: {
    color: '#DADADA',
    fontSize: 10,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '12px',
    letterSpacing: 0.12,
    textAlign: 'center',
  },
  titleActive: {
    color: theme.palette.primary.main,
  },
}));

/**
 * Элемент навигационной панели
 * @type {React.NamedExoticComponent<Props>}
 */
const BottomNavigationItem = memo((props: Props) => {
  const {Icon, title, active, ...rest} = props;
  const mc = useStyles();
  const iconClassName = c(mc.icon, {[mc.iconActive]: active});
  const titleClassName = c(mc.title, {[mc.titleActive]: active});

  return (
    <div className={mc.root} {...rest}>
      <Icon className={iconClassName}/>
      <div className={titleClassName}>{title}</div>
    </div>
  );
});

export default BottomNavigationItem;

import VKDiv, {DivProps} from '@vkontakte/vkui/dist/components/Div/Div';
import styled from '@material-ui/styles/styled/styled';
import {Theme} from '../../theme/types';

const Div = styled(VKDiv)<Theme, DivProps>(({theme}) => ({
  paddingLeft: 16,
  paddingRight: 16,
}));

export default Div;

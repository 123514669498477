import React, {memo} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../theme/types';

import Spinner from '@vkontakte/vkui/dist/components/Spinner/Spinner';

interface Props {
  className?: string;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    margin: '30px auto 0',
  },
}));

/**
 * Лоадер загрузки вью
 * @type {React.NamedExoticComponent<Props>}
 */
const ViewLoadingSpinner = memo((props: Props) => {
  const {className} = props;
  const mc = useStyles(props);

  return <Spinner className={c(mc.root, className)} size={'regular'}/>;
});

export default ViewLoadingSpinner;

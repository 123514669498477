import {COLOR} from 'bridge';

type CostumeColors = Record<COLOR, {
  helmet: string;
  mask: string;
  legs: string;
  hands: string;
  body: string;
  bodyShadow: string;
  cart: string;
}>;

const colors: CostumeColors = {
  [COLOR.CYAN]: {
    helmet: '#03C5D7',
    mask: '#275183',
    legs: '#03C5D7',
    hands: '#03C5D7',
    body: '#06A3B1',
    bodyShadow: '#048894',
    cart: '#00DBF0',
  },
  [COLOR.BLUE]: {
    helmet: '#4B9FFF',
    mask: '#275183',
    legs: '#4B9FFF',
    hands: '#4B9FFF',
    body: '#0077FF',
    bodyShadow: '#0967D2',
    cart: '#218AFF',
  },
  [COLOR.PINK]: {
    helmet: '#FF94BD',
    mask: '#FF3985',
    legs: '#FF3985',
    hands: '#FF94BD',
    body: '#E31262',
    bodyShadow: '#C30B52',
    cart: '#FF529E',
  },
  [COLOR.PURPLE]: {
    helmet: '#A85DDC',
    mask: '#54508F',
    legs: '#A85DDC',
    hands: '#A85DDC',
    body: '#6D69AB',
    bodyShadow: '#54508F',
    cart: '#AD21ED',
  },
  [COLOR.GREEN]: {
    helmet: '#75F3BE',
    mask: '#10B46E',
    legs: '#75F3BE',
    hands: '#75F3BE',
    body: '#17D685',
    bodyShadow: '#10B46E',
    cart: '#00F2AD',
  },
};

/**
 * Возвращает hex-цвет карта
 * @param {COLOR} color
 * @returns {string}
 */
export function getColors(color: COLOR) {
  return colors[color];
}

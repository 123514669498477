import React, {memo, ReactNode, useEffect, useState} from 'react';

import View from '../../View';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Tabs from '@vkontakte/vkui/dist/components/Tabs/Tabs';
import TabsItem from '@vkontakte/vkui/dist/components/TabsItem/TabsItem';
import ViewLoadingSpinner from '../../ViewLoadingSpinner';
import ViewLoadingError from '../../ViewLoadingError';
import RatingDay from './RatingDay';

import {useQuery} from '@apollo/react-hooks';
import {twoLastRatingDaysQuery, TwoLastRatingDaysQuery} from 'bridge';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {dayjsFromDateScalar} from '../../../utils/misc';
import HeadTitle from '../../HeadTitle'
import makeStyles from '@material-ui/styles/makeStyles'

dayjs.locale('ru');


const useStyles = makeStyles(() => ({
  hr: {
    width: 'calc(100% - 32px)',
    margin: '0 auto',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: 5,
  },
}));

const RatingView = memo(() => {
  const mc = useStyles();
  const {loading, error, data} =
    useQuery<TwoLastRatingDaysQuery>(twoLastRatingDaysQuery, {
      fetchPolicy: 'network-only',
    });
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  let content: ReactNode = null;

  // После того как загрузка прошла успешно, выбираем день
  useEffect(() => {
    if (!loading && data && data.ratingDates[0]) {
      setSelectedDay(data.ratingDates.slice(-1)[0].id);
    }
  }, [loading, data]);

  if (loading) {
    content = <ViewLoadingSpinner/>;
  } else if (error) {
    content = (
      <ViewLoadingError>
        Не удалось загрузить турнирную таблицу
      </ViewLoadingError>
    );
  } else if (data) {
    const ratingDates = data.ratingDates;
    content = (
      <>
        <Tabs mode={'default'} style={{ overflowX: 'auto' }}>
          {ratingDates.map(({date, id}) => {
            return (
              <TabsItem
                selected={selectedDay === id}
                onClick={() => setSelectedDay(id)}
                key={id}
              >
                {dayjsFromDateScalar(date).format('D MMMM')}
              </TabsItem>
            )
          })}
        </Tabs>
        <div className={mc.hr}/>
        {selectedDay !== null && <RatingDay id={selectedDay}/>}
      </>
    );
  }

  return (
    <View activePanel={'main'}>
      <Panel id={'main'}>
        <HeadTitle>Рейтинг</HeadTitle>
        {/*<ViewTitle>Турнирная таблица</ViewTitle>*/}
        {content}
      </Panel>
    </View>
  );
});

export default RatingView;

import React, { memo } from 'react'

import makeStyles from '@material-ui/styles/makeStyles'
import { Theme } from '../theme/types'

import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader'

const useStyles = makeStyles((theme: Theme) => ({
  headTitle: {
    fontWeight: 600,
    fontSize: '23px',
    lineHeight: '28px',
    color: '#000000',
    padding: '10px 16px',
    height: '100%',
    display: 'block'
  },
}));

interface HasChildren {
  children?: React.ReactNode;
}

const HeadTitleInner: React.FunctionComponent<HasChildren>
  = (props: HasChildren) => {
  const mc = useStyles();
  return (
    <PanelHeader left={<span className={mc.headTitle}>{props.children}</span>}/>
  );
}

/**
 * @type {React.NamedExoticComponent<any>}
 */
const HeadTitle = memo(HeadTitleInner);

export default HeadTitle;

import React, {memo, SVGProps} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../theme/types';

import {ReactComponent as SVGHelmet} from '../../assets/helmet.svg';

import {COLOR} from 'bridge';

import {getColor} from './colors';

interface Props extends SVGProps<SVGSVGElement> {
  color?: COLOR;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    display: 'block',
    '& .mask': {
      fill: ({color}: Props) => color ? getColor(color).mask : '',
    },
    '& .helmet': {
      fill: ({color}: Props) => color ? getColor(color).helmet : '',
    },
  },
}));

/**
 * Шлем который можно окрасить
 * @type {React.NamedExoticComponent<Props>}
 */
const Helmet = memo((props: Props) => {
  const {className, ...rest} = props;
  const mc = useStyles(props);

  return <SVGHelmet className={c(mc.root, className)} {...rest}/>;
});

export default Helmet;

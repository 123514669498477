import React, {memo, useCallback, useState} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import {Theme} from '../../theme/types';

import {copyToClipboard} from '../../utils/copying';

import FixedLayout
  from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import ModalPage from '@vkontakte/vkui/dist/components/ModalPage/ModalPage';
import ModalRoot from '@vkontakte/vkui/dist/components/ModalRoot/ModalRoot';
import ModalPageHeader from '../ModalPageHeader';
import Road from '../Road';
import PanelHeaderButton
  from '@vkontakte/vkui/dist/components/PanelHeaderButton/PanelHeaderButton';
// @ts-ignore FIXME: https://github.com/VKCOM/icons/issues/14
import CopyIcon from '@vkontakte/icons/dist/24/copy';
// @ts-ignore FIXME: https://github.com/VKCOM/icons/issues/14
import DismissIcon from '@vkontakte/icons/dist/24/dismiss';
import Car from '../Car/Car';

import {CarBreak} from '../Car/types';

interface Props {
  onRestartClick(): void;
  error: string;
}

const BrokenCar = withStyles({
  root: {
    position: 'absolute',
    animation: '$car-appear 1000ms cubic-bezier(0.25, 0.1, 0.77, 1.11)',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 15,
  },
  carBreak: {
    animationName: '$break-appear, $break-shake',
    animationDuration: '1200ms, 1000ms',
    animationIterationCount: '1, infinite',
    animationTimingFunction: 'cubic-bezier(0.5, 0.76, 0.85, 1.13), ease',
    transformOrigin: '50% 100%',
  },
  '@keyframes car-appear': {
    from: {left: 0, transform: 'translateX(-100%)'},
    to: {left: '50%', transform: 'translateX(-50%)'},
  },
  '@keyframes break-appear': {
    from: {transform: 'rotate(-60deg) scaleY(1.8)'},
    to: {opacity: 'rotate(-2deg) scaleY(1)'},
  },
  '@keyframes break-shake': {
    from: {transform: 'rotate(-2deg)'},
    '50%': {transform: 'rotate(2deg)'},
    to: {transform: 'rotate(-2deg)'},
  }
})(Car);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  race: {
    position: 'relative',
  },
  button: {
    '& + &': {
      marginTop: 10,
    },
  },
  title: {
    textAlign: 'center',
    fontSize: 28,
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '28px',
    margin: '0 auto 150px',
  },
  error: {
    whiteSpace: 'pre-wrap',
  },
  header: {
    fontSize: 21,
    lineHeight: '26px',
  },
  dismissIcon: {
    color: '#818c99',
  },
}));

/**
 * Вью которая отображается в случае, когда в приложении произошла ошибка.
 * @type {React.NamedExoticComponent<Props>}
 */
const AppCrashedView = memo((props: Props) => {
  const {onRestartClick, error} = props;
  const mc = useStyles(props);
  const [showError, setShowError] = useState(false);
  const [copying, setCopying] = useState(false);

  const onCopyClick = useCallback(async () => {
    setCopying(true);
    try {
      await copyToClipboard(error);
    } catch (e) {
    }
    setCopying(false);
  }, [error]);

  return (
    <>
      <div className={mc.root}>
        <div className={mc.race}>
          <div className={mc.title}>Упс, что-то сломалось</div>
          <BrokenCar carBreak={CarBreak.EngineBurn}/>
          <Road/>
        </div>
        <FixedLayout vertical={'bottom'}>
          <Div>
            <Button
              size={'xl'}
              mode={'primary'}
              onClick={onRestartClick}
              className={mc.button}
            >
              Перезапустить приложение
            </Button>
            <Button
              size={'xl'}
              mode={'secondary'}
              onClick={() => setShowError(true)}
              className={mc.button}
            >
              Подробнее об ошибке
            </Button>
          </Div>
        </FixedLayout>
      </div>
      {/*// TODO ModalRoot global context */}
      <ModalRoot activeModal={showError ? '1' : null}>
        <ModalPage
          id={'1'}
          header={
            <ModalPageHeader right={
              <PanelHeaderButton onClick={() => setShowError(false)}>
                <DismissIcon className={mc.dismissIcon}/>
              </PanelHeaderButton>
            }>
              Ошибка
            </ModalPageHeader>
          }
          onClose={() => setShowError(false)}
        >
          <Div className={mc.error}>{error}</Div>
          <Div>
            <Button
              size={'xl'}
              mode={'secondary'}
              before={<CopyIcon/>}
              onClick={onCopyClick}
              disabled={copying}
            >
              Скопировать
            </Button>
          </Div>
        </ModalPage>
      </ModalRoot>
    </>
  );
});

export default AppCrashedView;

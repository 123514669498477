import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Div from '../../atomic/Div';

import timerLeftSvg from '../../../assets/timer-left.svg';
import timerCenterSvg from '../../../assets/timer-center.svg';
import timerRightSvg from '../../../assets/timer-right.svg';

import dayjs, {Dayjs} from 'dayjs';
import {formatTimePart} from '../../../utils/misc';

import {DateTime} from 'bridge';

interface Props {
  questionNumber: number;
  questionsCount: number;
  questionSentAt: DateTime;
  currentTime: DateTime;
  speed: number;
  onTimeExpired(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 366,
    margin: '0 auto 14px',
    paddingTop: 0,
  },
  inner: {
    position: 'relative',
  },
  timer: {
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: 600,
    textAlign: 'center',
    textTransform: 'uppercase',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    color: '#0077FF',
  },
  sideTimer: {
    position: 'absolute',
    top: '50%',
    width: 103,
    height: 103,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    transform: 'translateY(-40%)',
  },
  sideTimerLeft: {
    left: 0,
    backgroundImage: `url(${timerLeftSvg})`,
  },
  sideTimerRight: {
    right: 0,
    color: '#0077FF',
    backgroundImage: `url(${timerRightSvg})`,
  },
  centerTimer: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '0 auto',
    color: '#0077FF',
    background: `url(${timerCenterSvg}) no-repeat center`,
    backgroundSize: 'contain',
    width: 130,
    height: 149,
  },
  centerTimerInner: {
    paddingTop: 30,
    height: '100%',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: 14,
    lineHeight: '9px',
    marginBottom: 14,
  },
  titleCenter: {
    marginBottom: 7,
    lineHeight: '14px',
  },
  caption: {
    fontSize: 25,
    lineHeight: '18px',
  },
  subtitle: {
    position: 'absolute',
    fontSize: 10,
    lineHeight: '9px',
    bottom: 12,
    left: 0,
    right: 0,
  },
}));

/**
 * Форматирует оставшееся время для дачи ответа
 * @returns {string}
 * @param seconds
 */
function formatSeconds(seconds: number): string {
  let s = seconds;
  const m = Math.floor(seconds / 60);
  s -= m * 60;

  return `${formatTimePart(m, 2)}:${formatTimePart(s, 2)}`;
}

const GameRaceTimer = memo((props: Props) => {
  const {
    questionNumber, speed, currentTime, questionSentAt, onTimeExpired,
    questionsCount,
  } = props;
  const mc = useStyles();

  // Текущее время относительно серверного
  const nowRef = useRef<Dayjs>(dayjs(currentTime));

  // Возвращает оставшееся время
  const getSecondsLeft = useCallback(() => {
    const timeLeft = 15 - nowRef.current.diff(questionSentAt, 'second');
    return timeLeft < 0 ? 0 : timeLeft;
  }, [questionSentAt]);

  // Время оставшееся на ответ
  const [secondsLeft, setSecondsLeft] = useState(getSecondsLeft);

  // Как только вопрос изменился, обновляем время на ответ и начинаем отсчет
  // заново
  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setSecondsLeft(seconds => seconds > 0 ? seconds - 1 : 0);
    }, 1000);
    setSecondsLeft(15);

    return () => clearInterval(intervalId);
  }, [questionNumber]);

  // Учитываем каждую секунду которая произошла с момента маунта компонента
  useEffect(() => {
    const intervalId = window.setInterval(() => {
      nowRef.current = nowRef.current.add(1, 'second');
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  // Как только время истекает, уведомляем родительский компонент
  useEffect(() => {
    if (secondsLeft === 0) {
      onTimeExpired();
    }
  }, [secondsLeft, onTimeExpired]);

  // Как только пришло новое серверное время, запоминаем его
  useEffect(() => {
    nowRef.current = dayjs(currentTime);
  }, [currentTime]);

  // То же самое касается и оставшегося времени на ответ
  useEffect(() => setSecondsLeft(getSecondsLeft()), [getSecondsLeft]);

  return (
    <Div className={mc.root}>
      <div className={mc.inner}>
        <div className={c(mc.timer, mc.sideTimer, mc.sideTimerLeft)}>
          <div className={mc.title}>Скорость</div>
          <div className={mc.caption}>{speed}</div>
          <div className={mc.subtitle}>км/ч</div>
        </div>
        <div className={c(mc.timer, mc.centerTimer)}>
          <div className={mc.centerTimerInner}>
            <div className={c(mc.title, mc.titleCenter)}>
              Время<br/>вопроса
            </div>
            <div className={mc.caption}>
              {formatSeconds(secondsLeft)}
            </div>
          </div>
        </div>
        <div className={c(mc.timer, mc.sideTimer, mc.sideTimerRight)}>
          <div className={mc.title}>Вопрос</div>
          <div className={mc.caption}>
            {questionNumber}/{questionsCount}
          </div>
        </div>
      </div>
    </Div>
  );
});

export default GameRaceTimer;

import React, {memo} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import imageUrl from '../../../assets/onboarding-3.base64.svg';

interface Props {
  onButtonClick(): any;
}

const useStyles = makeStyles<Theme>(theme => ({
  top: {
    position: 'relative',
    flex: '1 0 0',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    left: 0,
    width: '100%',
    bottom: 0,
  },
  bottom: {
    flex: '0 0 auto',
    paddingTop: 30,
  },
}));

/**
 * Третий слайд
 * @type {React.NamedExoticComponent<Props>}
 */
const ThirdSlide = memo((props: Props) => {
  const {onButtonClick} = props;
  const mc = useStyles(props);

  return (
    <>
      <div className={mc.top}>
        <img className={mc.image} src={imageUrl} alt={''}/>
      </div>
      <Div className={mc.bottom}>
        <Button size={'xl'} onClick={onButtonClick}>
          Принять участие в гонке
        </Button>
      </Div>
    </>
  );
});

export default ThirdSlide;

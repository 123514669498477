import React, {memo, useCallback} from 'react';

import View from '../../View';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import ViewLoadingSpinner from '../../ViewLoadingSpinner';
import ViewLoadingError from '../../ViewLoadingError';
import StartRace from './StartRace';
import GameResultView from './GameResultView';
import GameRace from './GameRace';

import {useQuery} from '@apollo/react-hooks';
import {NetworkStatus} from 'apollo-boost';
import {
  gameStatusQuery,
  GameStatusQuery,
} from 'bridge';

const GameView = memo(() => {
  const {loading, data, error, networkStatus, refetch} =
    useQuery<GameStatusQuery>(gameStatusQuery, {
      fetchPolicy: 'network-only',
    });
  const memoizedFetch = useCallback(() => refetch(), [refetch]);

  // Если ошибка или refetch, отрисовываем спиннер
  if (loading || networkStatus === NetworkStatus.refetch) {
    return (
      <View activePanel={'main'}>
        <Panel id={'main'}>
          <ViewLoadingSpinner/>
        </Panel>
      </View>
    );
  }

  // Если ошибка, отрисовываем текст с ошибкйо
  if (error) {
    return (
      <View activePanel={'main'}>
        <Panel id={'main'}>
          <ViewLoadingError>
            Не удалось загрузить информацию о статусе игры
          </ViewLoadingError>
        </Panel>
      </View>
    );
  }

  if (data) {
    const {
      racesCountAvailable, hasActiveRace, lobbyId: openedLobbyId, lastRace,
      racesCountThisDay, isDayRideable, lobbyAccepted,
    } = data.gameStatus;
    const canRide = racesCountAvailable > 0;
    console.log(data)

    // Если у пользователя есть активный заезд, переходим к нему
    if (hasActiveRace) {
      return <GameRace onRaceCompleted={memoizedFetch}/>;
    }

    // Отображаем вью с таймером и машинкой в случае, когда пользователь еще
    // вообще не ездил. Либо в случае, когда у него есть заезд чемпионов
    if (openedLobbyId || !lastRace) {
      return (
        <StartRace
          canRide={canRide}
          lobbyId={openedLobbyId}
          lobbyAccepted={!!lobbyAccepted}
          isDayRideable={isDayRideable}
          onRaceStarted={memoizedFetch}
        />
      );
    }
    // В остальных случаях показываем вью с большим таймером
    const {averageSpeed, time, correctAnswersCount, lobbyId, position} = lastRace;

    return (
      <GameResultView
        averageSpeed={averageSpeed}
        correctAnswersCount={correctAnswersCount}
        lastRaceLobbyId={lobbyId}
        openedLobbyId={openedLobbyId}
        position={position}
        racesCountAvailable={racesCountAvailable}
        racesCountThisDay={racesCountThisDay}
        time={time}
        onRaceStarted={memoizedFetch}
      />
    );
  }

  return null;
});

export default GameView;

import { COLOR } from 'bridge'
import { getColors } from '../CarFront/colors'

type Colors = Record<COLOR, {
  mask: string;
  helmet: string;
}>;

const colors: Colors = {
  [COLOR.CYAN]: {
    helmet: getColors(COLOR.CYAN).helmet,
    mask: getColors(COLOR.CYAN).mask,
  },
  [COLOR.BLUE]: {
    helmet: getColors(COLOR.BLUE).helmet,
    mask: getColors(COLOR.BLUE).mask,
  },
  [COLOR.PINK]: {
    helmet: getColors(COLOR.PINK).helmet,
    mask: getColors(COLOR.PINK).mask,
  },
  [COLOR.PURPLE]: {
    helmet: getColors(COLOR.PURPLE).helmet,
    mask: getColors(COLOR.PURPLE).mask,
  },
  [COLOR.GREEN]: {
    helmet: getColors(COLOR.GREEN).helmet,
    mask: getColors(COLOR.GREEN).mask,
  },
};

/**
 * Возвращает hex-цвета для шлема
 * @param {COLOR} color
 * @returns {string}
 */
export function getColor(color: COLOR) {
  return colors[color];
}


import React, { useMemo } from 'react'

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../theme/types';

import View from '../View';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import Link from '@vkontakte/vkui/dist/components/Link/Link';
import Div from '../atomic/Div';

import {isWeb} from '../../utils/is-web'

import vkLogoUrl from '../../assets/faq-vk.svg';
import imgHeart from '../../assets/faq-heart.svg';
import imgRules from '../../assets/faq-rules.svg';
import imgUsers from '../../assets/faq-users.svg';
import imgQuestion from '../../assets/faq-question.svg';
import imgInfo from '../../assets/faq-info.svg';
import HeadTitle from '../HeadTitle'
import { useQuery } from '@apollo/react-hooks'
import {
  AdminSettings,
  adminSettingsQuery,
  AdminSettingsQuery,
} from 'bridge'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .Panel__in': {
      height: '100%',
    },
  },
  hr: {
    width: 'calc(100% - 32px)',
    margin: '0 auto',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: 5,
  },
  container: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingBottom: 55,
  },
  itemContainer: {
    // flex: '1 0 0',
  },
  item: {
    height: 'calc(100% - 1px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 48,
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  itemImage: {
    display: 'inline-block',
    marginRight: 16,
    height: 28,
    width: 28,
  },
  itemImageSmall: {
    width: 20,
    marginLeft: 4,
    marginRight: 20,
  },
  itemText: {
    display: 'inline-block',
    textDecoration: 'none',
    color: 'black',
    // color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '18px',
    letterSpacing: -0.154,
    textAlign: 'left',
  },
}));

const FAQView = () => {
  const mc = useStyles();

  const {loading, data} = useQuery<AdminSettingsQuery>(adminSettingsQuery, {
    fetchPolicy: 'network-only',
  });

  if (loading || !data) {
    return <View activePanel={'main'}>
      <Panel className={mc.root} id={'main'}>
        <HeadTitle>FAQ</HeadTitle>
      </Panel>
    </View>
  }

  const settings = data.adminSettings as AdminSettings

  const items = [{
    title: 'team',
    href: 'https://vk.com/vkteam',
    imgUrl: vkLogoUrl,
  }, {
    title: 'Оценить VK Tech Talks',
    href: settings.replyFormUrl || '',
    // 'https://vk.com/app5619682_-147415323#596604',
    imgUrl: imgHeart,
    small: true,
  }, {
    title: 'Присоединиться к VK Team',
    href: 'https://team.vk.company/vacancy/',
    imgUrl: imgUsers,
  }, {
    title: 'Задать вопрос',
    href: 'https://vk.com/im?media=&sel=-147415323&v=',
    imgUrl: imgQuestion,
    small: true,
  }, {
    title: 'Правила игры',
    href: 'https://m.vk.com/@vkcontest-minirace',
    imgUrl: imgRules,
    small: true,
  }, {
    // eslint-disable-next-line max-len
    title: (<>Разработано на платформе<br/>VK Mini Apps</>),
    href: 'https://vk.com/dev/vk_apps_docs',
    imgUrl: imgInfo,
    small: true,
  }];

  return (
    <View activePanel={'main'}>
      <Panel className={mc.root} id={'main'}>
        <HeadTitle>FAQ</HeadTitle>
        <div className={mc.hr}/>
        <div className={mc.container}>
          {items.map(({imgUrl, title, href, small}, idx) => {
            const content = (
              <>{imgUrl &&
                <img className={
                  `${mc.itemImage} ${small ? mc.itemImageSmall : ''}`
                } src={imgUrl} alt={''}/>
              }
              <div className={mc.itemText}>{title}</div></>
            );

            return (
              <div className={mc.itemContainer} key={idx}>
                {/*<Separator/>*/}
                <Div className={mc.item}>
                  {href
                    ? (
                      isWeb()
                      ? <a className={mc.inner} href={href} target="_blank"
                           rel="noopener noreferrer">{content}</a>
                      : <Link className={mc.inner} href={href}>{content}</Link>
                    )
                    : <div className={mc.inner}>{content}</div>}
                </Div>
              </div>
            )
          })}
          {/*<Separator/>*/}
        </div>
      </Panel>
    </View>
  );
};

export default FAQView;

import React, {HTMLProps, memo} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import useInsets from '../../../hooks/useInsets';

import {Insets as InsetsType} from '@vkontakte/vk-bridge';

interface Props extends HTMLProps<HTMLDivElement> {
  top?: boolean;
  bottom?: boolean;
}

const useStyles = makeStyles<Theme, Props & { insets: InsetsType }>({
  root: {
    paddingTop: ({insets, top}) => top ? insets.top : 0,
    paddingBottom: ({insets, bottom}) => bottom ? insets.bottom : 0,
  },
});

/**
 * Обертка которая учитывает инсеты
 * @type {React.NamedExoticComponent<Props>}
 */
const Insets = memo((props: Props) => {
  const insets = useInsets();
  const {className, top, bottom, ...rest} = props;
  const mc = useStyles({...props, insets});

  return <div className={c(mc.root, className)} {...rest}/>;
});

export default Insets;

import {Theme} from './types';

const defaultTheme: Theme = {
  breakpoints: {
    up: breakpoint => `@media screen and (min-width: ${breakpoint + 0.01}px)`
  },
  palette: {
    primary: {
      main: '#0177FD',
      vio: '#8024C0',
    }
  },
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontFamily: '-apple-system, Roboto, Helvetica Neue, Arial',
    fontFamilyTT: '"TT Commons", -apple-system, Roboto, Helvetica Neue, Arial',
  },
};

export default defaultTheme;

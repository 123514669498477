import React, {memo} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../theme/types';

import {ReactComponent as SVGCar} from '../../assets/car-in-front.svg';

import {COLOR} from 'bridge';
import {getColors} from './colors';

interface Props {
  cartColor?: COLOR;
  costumeColor?: COLOR;
  className?: string;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    '& .helmet': {
      fill: ({costumeColor}: Props) => costumeColor
        ? getColors(costumeColor).helmet : '',
    },
    '& .mask': {
      fill: ({costumeColor}: Props) => costumeColor
        ? getColors(costumeColor).mask : '',
    },
    '& .leg': {
      fill: ({costumeColor}: Props) => costumeColor
        ? getColors(costumeColor).legs : '',
    },
    '& .hand': {
      fill: ({costumeColor}: Props) => costumeColor
        ? getColors(costumeColor).hands : '',
    },
    '& .body': {
      fill: ({costumeColor}: Props) => costumeColor
        ? getColors(costumeColor).body : '',
    },
    '& .body-shadow': {
      fill: ({costumeColor}: Props) => costumeColor
        ? getColors(costumeColor).bodyShadow : '',
    },
    '& .cart': {
      fill: ({cartColor}: Props) => cartColor ? getColors(cartColor).cart : '',
    },
  },
}));

/**
 * Машинка в 2D, вид спереди
 * @type {React.NamedExoticComponent<Props>}
 */
const CarFront = memo((props: Props) => {
  const {className} = props;
  const mc = useStyles(props);

  return <SVGCar className={c(mc.root, className)}/>;
});

export default CarFront;

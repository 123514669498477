import React, { memo, useEffect, useState } from 'react'

import UserView from './UserView'
import CartView from './CartView'
import AppLoadingView from '../AppLoadingView'
import AppCrashView from '../AppCrashView'

import { useMutation } from '@apollo/react-hooks'
import useSelector from '../../../hooks/useSelector'
import useActions from '../../../hooks/useActions'
import { userActions } from '../../../redux/reducers/user'

import { registerMutation, RegisterMutation } from 'bridge'
import { CartRegistrationInfo, UserRegistrationInfo } from './types'
import { setStorageValue } from '../../../utils/storage'
import { StorageField } from '../../../types/bridge'

/**
 * Вью регистрации пользователя
 * @type {React.NamedExoticComponent<object>}
 */
const RegistrationView = memo(() => {
  const [user, setUser] = useState<UserRegistrationInfo | null>(null);
  const [cart, setCart] = useState<CartRegistrationInfo | null>(null);
  const {
    setUserRegistered,
    setCartColor,
    setCostumeColor,
    setUserIsAdmin,
  } = useActions({
    setUserRegistered: userActions.setUserRegistered,
    setCartColor: userActions.setCartColor,
    setCostumeColor: userActions.setCostumeColor,
    setUserIsAdmin: userActions.setUserIsAdmin,
  });
  const profileImageUrl = useSelector(state => state.user.profileImageUrl);
  const isAdmin = useSelector(state => state.user.isAdmin);
  const [register, {loading, error, data}] =
    useMutation<RegisterMutation, RegisterMutation.Arguments>(registerMutation);

  useEffect(() => {
    // Когда информация о пользователе и карте известны, устанавливаем флаг
    // что форма заполнена
    if (user && cart) {
      register({
        variables: {
          input: {
            ...cart,
            ...user,
            profileImageUrl,
          },
        },
      });
    }
  }, [user, cart, register, profileImageUrl]);

  useEffect(() => {
    // Как только пользователь зарегистрирован, ставим флаг в Redux
    if (data) {
      setUserRegistered();
      setUserIsAdmin(isAdmin)
      setStorageValue(StorageField.ForceRegister, false)

      if (cart) {
        setCostumeColor(cart.costumeColor);
        setCartColor(cart.cartColor);
      }
    }
  }, [
    data, setUserRegistered, setCartColor,
    setCostumeColor, cart, isAdmin, setUserIsAdmin
  ]);

  // Вью с информацией о пользователе
  if (!user) {
    return <UserView onCompleted={setUser}/>;
  }

  // Вью с информацией о карте
  if (!cart) {
    return <CartView onCompleted={setCart}/>;
  }

  // Пока запрос отправляется, отображаем лоадер
  if (loading) {
    return <AppLoadingView/>;
  }

  // При возникновении ошибки отображаем вью краша
  if (error) {
    return <AppCrashView onRestartClick={register} error={error.message}/>;
  }
  return null;
});

export default RegistrationView;

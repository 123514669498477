import {useSubscription} from '@apollo/react-hooks';
import {UserRace, userRaceSubscription, UserRaceSubscription} from 'bridge';
import {useEffect} from 'react';

/**
 * Создает подиску на текущий заезд пользователя
 * @returns {UserRace | null}
 */
function useUserRaceSubscription(
  shouldResubscribe: boolean,
  onUserRaceReceived?: () => void,
): UserRace | null {
  const {data} = useSubscription<UserRaceSubscription,
    UserRaceSubscription.Arguments>(userRaceSubscription, {
    shouldResubscribe,
    fetchPolicy: 'network-only',
  });

  // Как только пришли данные о пользовательском заезде, вызываем коллбэк
  useEffect(() => {
    if (data && data.userRace && onUserRaceReceived) {
      onUserRaceReceived();
    }
  }, [data, onUserRaceReceived]);

  return data ? data.userRace : null;
}

export default useUserRaceSubscription;

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import '@vkontakte/vkui/dist/vkui.css';

dayjs.extend(customParseFormat);

// Ожидаем загрузки всех ассетов чтобы убедиться, что все шрифты, css и js
// загружены.
window.onload = () => {
  // Отображаем приложение.
  ReactDOM.render(<Root/>, document.getElementById('root'));
};

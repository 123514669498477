import React, {memo} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../theme/types';

import {ReactComponent as SVGCar} from '../../assets/car-in-profile.svg';

import {CarBreak, SVGComponentType} from './types';
import {COLOR} from 'bridge';

import breakComponents from './break-components';
import {getCartColor, getCostumeColors} from './colors';

interface Props {
  cartColor?: COLOR;
  costumeColor?: COLOR;
  className?: string;
  breakClassName?: string;
  carBreak?: CarBreak;
}

const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    position: 'relative',
    display: 'inline-block',
    width: 245,
  },
  car: {
    display: 'block',

    '& .engine': {
      animation: '$engine-shake 150ms linear infinite',
    },
    '& .costume__helmet': {
      fill: ({costumeColor}: Props) => costumeColor
        ? getCostumeColors(costumeColor).helmet : '',
    },
    '& .costume__body': {
      fill: ({costumeColor}: Props) => costumeColor
        ? getCostumeColors(costumeColor).body : '',
    },
    '& .cart': {
      fill: ({cartColor}: Props) => cartColor ? getCartColor(cartColor) : '',
    },
  },
  carBreak: {},
  '@keyframes engine-shake': {
    from: {
      transform: 'translateY(0)',
    },
    to: {
      transform: 'translateY(1.5px)',
    },
  },
}));

/**
 * Преставляет собой машинку в 2D, вид сбоку
 * @type {React.NamedExoticComponent<Props>}
 */
const Car = memo((props: Props) => {
  const {className, carBreak, breakClassName} = props;
  const mc = useStyles(props);
  let BreakComponent: SVGComponentType | null = null;

  if (typeof carBreak !== 'undefined') {
    BreakComponent = breakComponents[carBreak];
  }

  return (
    <div className={c(mc.root, className)}>
      <SVGCar className={mc.car}/>
      {BreakComponent &&
      <BreakComponent className={c(mc.carBreak, breakClassName)}/>}
    </div>
  );
});

export default Car;

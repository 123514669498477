import React, {HTMLProps, memo} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';

interface Props extends HTMLProps<HTMLDivElement> {
}

const useStyles = makeStyles({
  root: {
    '&:before': {
      content: '""',
      display: 'block',
      background: '#A1C9FF',
      height: 32,
    },
    '&:after': {
      content: '""',
      display: 'block',
      background: 'repeating-linear-gradient(90deg, #4986CC 0px, ' +
        '#4986CC 30px, white 30px, white 60px)',
      height: 8,
    },
    /*'&:before': {
      content: '""',
      display: 'block',
      background: 'linear-gradient(180deg, #4B69A5 0%, #BACDF2 100%)',
      height: 32,
    },

    '&:after': {
      content: '""',
      display: 'block',
      background: 'repeating-linear-gradient(90deg, #495E80 0px, ' +
        '#495E80 30px, white 30px, white 60px)',
      height: 8,
    },*/
  },
});

/**
 * Представляет собой 2D дорогу
 * @type {React.NamedExoticComponent<Props>}
 */
const Road = memo((props: Props) => {
  const {className, ...rest} = props;
  const mc = useStyles(props);

  return <div className={c(mc.root, className)} {...rest}/>;
});

export default Road;

import React, {memo} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Car from '../../Car';
import Road from '../../Road';
import {ReactComponent as SVGFinishLine} from '../../../assets/game-finish-line.svg';

import {COLOR} from 'bridge';

interface Props {
  cartColor: COLOR;
  costumeColor: COLOR;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    marginBottom: 16,
  },
  cart: {
    display: 'block',
    margin: '0 0 -20px 16px',
    width: 165,
  },
  road: {
    '&:before': {
      height: 26,
    },
    '&:after': {
      height: 6,
    },
  },
  finishLine: {
    position: 'absolute',
    right: 24,
    bottom: 6,
  },
}));

const GameRaceRoad = memo((props: Props) => {
  const {cartColor, costumeColor} = props;
  const mc = useStyles();

  return (
    <div className={mc.root}>
      <Car
        className={mc.cart}
        cartColor={cartColor}
        costumeColor={costumeColor}
      />
      <Road className={mc.road}/>
      <SVGFinishLine className={mc.finishLine}/>
    </div>
  );
});

export default GameRaceRoad;

import React, {HTMLProps, memo} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../theme/types';

import Div from './atomic/Div';

interface Props extends HTMLProps<HTMLDivElement> {
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    paddingTop: 10,
  },
}));

/**
 * Ошибка загрузки вью
 * @type {React.NamedExoticComponent<Props>}
 */
const ViewLoadingError = memo((props: Props) => {
  const {className, ...rest} = props;
  const mc = useStyles(props);

  return <Div className={c(mc.root, className)} {...rest}/>;
});

export default ViewLoadingError;

import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react'

import makeStyles from '@material-ui/styles/makeStyles'
import { Theme } from '../../theme/types'

import View from '../View'
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel'
import Separator from '../atomic/Separator'
import Div from '../atomic/Div'
import Car from '../Car'
import ColorSelector from '../ColorSelector'
import UserCard from '../UserCard'
import ViewLoadingSpinner from '../ViewLoadingSpinner'
import ViewLoadingError from '../ViewLoadingError'

import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  updateCartColorMutation,
  UpdateCartColorMutation,
  updateCostumeColorMutation,
  UpdateCostumeColorMutation,
  userProfileQuery,
  UserProfileQuery,
} from 'bridge'

import { getFieldTitle } from '../../utils/enums'
import { tapticNotification } from '../../utils/taptic'
import useActions from '../../hooks/useActions'
import { userActions } from '../../redux/reducers/user'
import useSelector from '../../hooks/useSelector'
import { Button } from '@vkontakte/vkui'
import { setStorageValues } from '../../utils/storage'
import { StorageField } from '../../types/bridge'
import HeadTitle from '../HeadTitle'

const useStyles = makeStyles((theme: Theme) => ({
  userCardContent: {
    color: '#818C99',
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  time: {
    color: '#9690EA',
    letterSpacing: -0.154,
    lineHeight: '18px',
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 6,
  },
  speed: {
    display: 'flex',
    alignItems: 'center',

    '&:before': {
      display: 'block',
      content: '""',
      height: 12,
      borderRight: `2px ${theme.palette.primary.main} solid`,
      marginRight: 6,
    },
  },
  cart: {
    display: 'table',
    margin: '20px auto 10px',
  },
  resetButton: {
  },
  becomeNotAdminButton: {
    marginTop: '10px'
  },
  panel: {
    '& .Panel__in': {
      paddingBottom: '50px'
    }
  }
}));

/**
 * Раздел с профилем пользователя
 * @type {React.NamedExoticComponent<any>}
 */
const ProfileView = memo(() => {
  const mc = useStyles();
  let content: ReactNode = null;

  const {
    initialCartColor,
    initialCostumeColor,
    isAdmin
  } = useSelector(state => ({
    initialCartColor: state.user.cartColor,
    initialCostumeColor: state.user.costumeColor,
    isAdmin: state.user.isAdmin,
  }));
  const {setReduxCartColor, setReduxCostumeColor, setUserIsAdmin} = useActions({
    setReduxCartColor: userActions.setCartColor,
    setReduxCostumeColor: userActions.setCostumeColor,
    setUserIsAdmin: userActions.setUserIsAdmin,
  });
  const [cartColor, setCartColor] = useState(initialCartColor);
  const [costumeColor, setCostumeColor] = useState(initialCostumeColor);

  const {loading, error, data} = useQuery<UserProfileQuery>(userProfileQuery, {
    fetchPolicy: 'network-only',
  });
  const [updateCartColor] =
    useMutation<UpdateCartColorMutation, UpdateCartColorMutation.Arguments>(
      updateCartColorMutation,
    );
  const [updateCostumeColor] = useMutation<UpdateCostumeColorMutation,
    UpdateCostumeColorMutation.Arguments>(
    updateCostumeColorMutation,
  );

  // Сохранение цвета карта
  const onCartColorSave = useCallback(async (c) => {
    setReduxCartColor(c);
    await tapticNotification('success');
    return updateCartColor({variables: {color: c}});
  }, [updateCartColor, setReduxCartColor, cartColor]);

  // Сохранение цвета костюма
  const onCostumeColorSave = useCallback(async (c) => {
    setReduxCostumeColor(c);
    await tapticNotification('success');
    return updateCostumeColor({variables: {color: c}});
  }, [updateCostumeColor, setReduxCostumeColor]);

  useEffect(() => {
    // Как только данные о профиле пользователя были получены, записываем
    // их в состояние
    if (!loading && data) {
      const {cartColor, costumeColor} = data.userProfile;
      setCartColor(cartColor);
      setCostumeColor(costumeColor);
    }
  }, [loading, data]);

  if (loading) {
    content = <ViewLoadingSpinner/>;
  } else if (error) {
    content = (
      <ViewLoadingError>Не удалось загрузить данные профиля</ViewLoadingError>
    );
  } else if (data) {
    const {
      name, bestAverageSpeed, bestTime, field, profileImageUrl, gamesInDay,
      gamesPlayed
    } = data.userProfile;

    content = (
      <>
        <Div>
          <UserCard
            name={name}
            bestAverageSpeed={bestAverageSpeed}
            bestTime={bestTime}
            profileImageUrl={profileImageUrl}
            costumeColor={costumeColor}
          >
            <div className={mc.userCardContent}>
              Команда: {getFieldTitle(field)}
              {/*gamesInDay > 0 &&
              <><br/>Сыграно {gamesPlayed} игр из {gamesInDay} на сегодня</>*/}
            </div>
          </UserCard>
        </Div>
        <Separator/>
        <Car
          className={mc.cart}
          cartColor={cartColor}
          costumeColor={costumeColor}
        />
        <ColorSelector
          title={'Цвет карта'}
          color={cartColor}
          controlled={true}
          onChange={setCartColor}
          onSave={onCartColorSave}
        />
        <ColorSelector
          title={'Цвет гоночного костюма'}
          color={costumeColor}
          controlled={true}
          onChange={setCostumeColor}
          onSave={onCostumeColorSave}
        />
      </>
    );
  }

  const resetUser = async () => {
    await setStorageValues({
      [StorageField.OnboardingCompleted]: false,
      [StorageField.ForceRegister]: true
    })
    window.location.reload()
  }

  const becomeNotAdmin = async () => {
    await setUserIsAdmin(false)
  }

  const testError = () => {
    throw new Error('Тестовая ошибка: текст тестовой ошибки')
  }

  let resetButton = (<></>)
  if (isAdmin) {
    resetButton = (
      <Div>
        <Button size={'xl'} className={mc.resetButton} onClick={resetUser}>
          Сбросить профиль
        </Button>
        <Button size={'xl'}
                className={mc.becomeNotAdminButton} onClick={testError}>
          Выбросить ошибку
        </Button>
        <Button size={'xl'}
                className={mc.becomeNotAdminButton} onClick={becomeNotAdmin}>
          Стать не администратором (до перезагрузки\)
        </Button>
      </Div>
    )
  }

  return (
    <View activePanel={'main'}>
      <Panel id={'main'} className={mc.panel}>
        <HeadTitle>Профиль</HeadTitle>
        {/*<ViewTitle center={true}>Профиль</ViewTitle>*/}
        <Separator/>
        {content}
        {resetButton}
      </Panel>
    </View>
  );
});

export default ProfileView;

import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import View from '../../View';
import Div from '../../atomic/Div';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader
  from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import ViewTitle from '../../ViewTitle';
import Road from '../../Road';
import Car from '../../Car';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import {ReactComponent as SVGTimer} from '../../../assets/timer.svg';

import {useMutation} from 'react-apollo';
import useSelector from '../../../hooks/useSelector';
import {formatTimePart} from '../../../utils/misc';
import {
  acceptLobbyMutation,
  AcceptLobbyMutation,
  startRaceMutation,
  StartRaceMutation,
} from 'bridge';
import useLobbySubscription from '../../../hooks/useLobbySubscription';
import useUserRaceSubscription from '../../../hooks/useUserRaceSubscription';
import useActions from '../../../hooks/useActions';
import {layoutActions} from '../../../redux/reducers/layout';
import { isWeb } from '../../../utils/is-web'
import Link from '@vkontakte/vkui/dist/components/Link/Link'

interface Props {
  canRide: boolean;
  lobbyId: string | null;
  lobbyAccepted: boolean;
  isDayRideable: boolean;
  onRaceStarted(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .Panel__in': {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
    },
  },
  top: {
    position: 'relative',
    paddingTop: 130,
    height: 305,
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'none',
    display: 'block',
    marginTop: 50
  },
  timer: {
    position: 'absolute',
    zIndex: -1,
    bottom: 40,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  bottomTitle: {
    textAlign: 'center',
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 28,
    lineHeight: '28px',
    marginBottom: 20,
    marginTop: 12,
  },
  car: {
    position: 'absolute',
    animation: '$car-appear 1000ms cubic-bezier(0.25, 0.1, 0.77, 1.11)',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 15,
  },
  inner: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'column',
    paddingBottom: 60,
  },
  countdown: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 50,
    lineHeight: '26px',
    fontFamily: theme.typography.fontFamilyTT,
    color: '#0077FF',
  },
  description: {
    textAlign: 'center',
    fontSize: 15,
    lineHeight: '20px',
    letterSpacing: -0.24,
    color: '#818C99',
    paddingBottom: 24,
    paddingTop: 20,
  },
  '@keyframes car-appear': {
    from: {left: 0, transform: 'translateX(-100%)'},
    to: {left: '50%', transform: 'translateX(-50%)'},
  },
}));

const StartRace = memo((props: Props) => {
  const {canRide, isDayRideable, lobbyId, onRaceStarted, lobbyAccepted} = props;
  const mc = useStyles();
  const {cartColor, costumeColor} = useSelector(state => ({
    cartColor: state.user.cartColor,
    costumeColor: state.user.costumeColor,
  }));
  const setNavigationShown = useActions(layoutActions.setNavigationShown);

  const [startRace, srMeta] = useMutation<StartRaceMutation>(startRaceMutation);
  const [acceptLobby, {data: acceptData}] = useMutation<AcceptLobbyMutation,
    AcceptLobbyMutation.Arguments>(acceptLobbyMutation);

  const intervalRef = useRef<number | null>(null);
  const [seconds, setSeconds] = useState(lobbyId
    ? 15
    : (!isDayRideable || !canRide ? 0 : 3));
  const [starting, setStarting] = useState(false);
  const [accepted, setAccepted] = useState(lobbyAccepted);

  const lobbyStartsIn = useLobbySubscription(
    lobbyId, !lobbyAccepted && (!acceptData || !acceptData.acceptLobby),
  );
  useUserRaceSubscription(true, onRaceStarted);

  const onAcceptClick = useCallback(() => {
    setAccepted(true);

    if (lobbyId) {
      acceptLobby({variables: {lobbyId}});
    }
  }, [lobbyId, acceptLobby]);
  const onStartClick = useCallback(() => setStarting(true), []);

  // Как только подтвердили свою готовность, начинаем отсчет
  useEffect(() => {
    if (starting) {
      intervalRef.current = window.setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);

      return () => {
        if (intervalRef.current !== null) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [starting]);

  // По приходу информации о запуске приватного заезда, начинаем отсчет
  useEffect(() => {
    if (lobbyStartsIn !== null) {
      setSeconds(lobbyStartsIn + 1);
      setStarting(true);
    }
  }, [lobbyStartsIn]);

  // Как только отсчет окончен, запускаем заезд если приватного нет, иначе
  // уведомляем родительский компонент
  useEffect(() => {
    if (seconds === -1 && intervalRef.current) {
      clearInterval(intervalRef.current);

      if (lobbyStartsIn === null) {
        startRace();
      } else {
        onRaceStarted();
      }
    }
  }, [seconds, startRace, onRaceStarted, lobbyStartsIn]);

  // После того как ответ по запросу начала заезда пришел, уведомляем
  // родительский компонент
  useEffect(() => {
    if (!srMeta.loading && !srMeta.error && srMeta.data) {
      onRaceStarted();
    }
  }, [srMeta.loading, srMeta.error, srMeta.data, onRaceStarted]);

  // When component is mounted, show navigation
  useEffect(() => {
    setNavigationShown(true);
  }, [setNavigationShown]);

  let content: ReactNode = null;

  if (lobbyId !== null) {
    content = (
      <Div>
        <Div className={mc.description}>
          {accepted ? (
            <>
              Ждем, когда другие игроки подтвердят<br/>
              участие в гонке
            </>
          ) : (
            <>
              Нажимайте кнопку только по команде<br/>
              ведущего на стенде VK
            </>
          )}
        </Div>
        <Button size={'xl'} disabled={accepted} onClick={onAcceptClick}>
          Присоединиться к заезду
        </Button>
      </Div>
    );
  } else if (!isDayRideable) {
    content = (
      /*<Div className={mc.description}>
        К сожалению, официальные заезды уже закончились. Приходите в следующем
        году!
      </Div>*/

    <Div>
      <Div className={mc.bottomTitle}>Сегодня заездов нет</Div>

      {
        isWeb()
          ? <a className={mc.link} href={'https://vk.com/vkteam'}
               target="_blank"
               rel="noopener noreferrer">
            <Button size={'xl'}>
              Подписаться на VK Team
            </Button>
          </a>
          : <Link className={mc.link}
            href={'https://vk.com/vkteam'}>
            <Button size={'xl'}>
              Подписаться на VK Team
            </Button>
          </Link>
      }
    </Div>
    );
  } else {
    content = (
      <Div>
        <Div className={mc.bottomTitle}>Поехали!</Div>
        <Button size={'xl'} disabled={starting} onClick={onStartClick}>
          Начать гонку
        </Button>
      </Div>
    );
  }

  return (
    <View className={mc.root} activePanel={'main'}>
      <Panel id={'main'}>
        {/*<PanelHeader>VK Mini Race</PanelHeader>*/}
        <div className={mc.inner}>
          <ViewTitle center={true}>{/*VK Mini Race*/}</ViewTitle>
          <div>
            <div className={mc.top}>
              <SVGTimer className={mc.timer}/>
              <div className={mc.countdown}>
                00:{seconds < 0 ? '00' : formatTimePart(seconds, 2)}
              </div>
              <Car
                className={mc.car}
                cartColor={cartColor}
                costumeColor={costumeColor}
              />
              <Road/>
            </div>
            {content}
          </div>
          <div/>
        </div>
      </Panel>
    </View>
  );
});

export default StartRace;

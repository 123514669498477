import React, {memo, useCallback, useEffect, useState} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Div from '../../atomic/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';

interface Props {
  index: number;
  title: string;
  options: string[];
  selectedOptions: number[];
  onOptionChoose(index: number): void;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    paddingBottom: 60,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    fontSize: 17,
    lineHeight: '22px',
    letterSpacing: -0.408,
    minHeight: 140,
    textAlign: 'center',
    marginBottom: 14,
  },
  option: {
    '&[disabled], &.Tappable--active': {
      opacity: 1,
    },

    '& + &': {
      marginTop: 10,
    },
  },
}));

/* const cache: { [index: number]: any } = {}

const shuffle = (index: number, arr: any[]): any[] => {
  if (!cache[index]) {
    cache[index] = JSON.parse(JSON.stringify(arr))
    cache[index].sort(() => Math.random() - 0.5)
  }
  return cache[index]
}

const destroyShuffleCache = (index: number): void => {
  cache[index] = null
  console.log(cache)
  delete cache[index]
  console.log(cache)
} */

const GameQuestion = memo((props: Props) => {
  const {title, options, onOptionChoose, selectedOptions, index} = props;
  const items: [string, number][] = options.map((el, idx) => [el, idx]);
  // items = shuffle(index, items);
  const mc = useStyles(props);
  const [blockedOpts, setBlockedOpts] = useState(selectedOptions);

  const onOptionClick = useCallback((optionIndex: number) => {
    // destroyShuffleCache(index);
    onOptionChoose(optionIndex);
    setBlockedOpts(blocked => [...blocked, optionIndex]);
  }, [onOptionChoose]);

  useEffect(() => setBlockedOpts([]), [index]);

  return (
    <Div className={mc.root}>
      <Div className={mc.title}>{title}</Div>
      {items.map(([o, idx]) => {
        const selected = blockedOpts.includes(idx);
        return (
          <Button
            className={mc.option}
            mode={selected ? 'secondary' : 'primary'}
            size={'xl'}
            onClick={() => onOptionClick(idx)}
            disabled={selected}
            key={idx}
          >
            {o}
          </Button>
        )
      })}
    </Div>
  );
});

export default GameQuestion;

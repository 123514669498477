/**
 * Список полей, которые могут храниться в хранилище bridge.
 */
export enum StorageField {
  OnboardingCompleted = 'onboarding-completed',
  ForceRegister = 'force-register',
}

/**
 * Описывает то, какое поле имеет какой тип данных в хранилище bridge.
 * Пример - [StorageField.Joined]: boolean или [StorageField.Clubs]: IClub[]
 */
export interface StorageValuesMap {
  [StorageField.OnboardingCompleted]: boolean;
  [StorageField.ForceRegister]: boolean;
}

/**
 * Возвращает тип данных для указанного поля хранилища.
 */
export type StorageValueType<T extends StorageField> = StorageValuesMap[T];

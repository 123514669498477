import React, {
  HTMLProps,
  memo,
  ReactNode,
  ReactNodeArray,
  useEffect,
  useRef,
} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';

import useEpicContext from './useEpicContext';

export interface EpicViewProps extends HTMLProps<HTMLDivElement> {
  id: string;
  keepMounted?: boolean;
  children?: ReactNode | ReactNodeArray;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  hidden: {
    display: 'none',
  },
});

/**
 * Вью в Epic
 * @type {React.NamedExoticComponent<EpicViewProps>}
 */
const EpicView = memo((props: EpicViewProps) => {
  const {id, keepMounted, className, ...rest} = props;
  const mc = useStyles(props);
  const mounted = useRef(false);
  const {activeView} = useEpicContext();
  const isActive = activeView === id;

  useEffect(() => {
    // Как только компонент впервые стал активен, это означает что он прикреплен
    // к DOM
    if (isActive && !mounted.current) {
      mounted.current = true;
    }
  }, [isActive]);

  // Отображаем контент в случае, когда вью активно, либо когда компонент
  // когда-то маунтился и при этом есть необходимость держать его в дереве
  if (
    isActive
    || (mounted.current && keepMounted)
  ) {
    const _className = c(mc.root, className, {
      [mc.hidden]: !isActive,
    });
    return <div className={_className} {...rest}/>;
  }
  return null;
});

export default EpicView;

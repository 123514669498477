import {ofType, unionize, UnionOf} from 'unionize';
import {unionizeConfig} from '../utils';

export interface LayoutReducerState {
  activeView: string;
  isNavigationShown: boolean;
}

export const layoutActions = unionize({
  setActiveView: ofType<string>(),
  setNavigationShown: ofType<boolean>(),
}, unionizeConfig);

type LayoutAction = UnionOf<typeof layoutActions>;

const initialState: LayoutReducerState = {
  activeView: '',
  isNavigationShown: true,
};

/**
 * Редьюсер который отвечает за визуальную сосотовляющую приложения.
 * @param {StorageReducerState} state
 * @param {StorageAction} action
 * @returns {string[]}
 */
function layoutReducer(
  state: LayoutReducerState = initialState,
  action: LayoutAction,
) {
  return layoutActions.match(action, {
    setActiveView: id => ({...state, activeView: id}),
    setNavigationShown: isNavigationShown => ({...state, isNavigationShown}),
    default: () => state,
  });
}

export default layoutReducer;

import { COLOR } from 'bridge'
import { getColors } from '../CarFront/colors'

type CartColors = Record<COLOR, string>;
type CostumeColors = Record<COLOR, {
  body: string;
  helmet: string;
}>;

const cartColors: CartColors = {
  [COLOR.CYAN]: getColors(COLOR.CYAN).cart,
  [COLOR.BLUE]: getColors(COLOR.BLUE).cart,
  [COLOR.PINK]: getColors(COLOR.PINK).cart,
  [COLOR.PURPLE]: getColors(COLOR.PURPLE).cart,
  [COLOR.GREEN]: getColors(COLOR.GREEN).cart,
};

const costumeColors: CostumeColors = {
  [COLOR.CYAN]: {
    body: getColors(COLOR.CYAN).body,
    helmet: getColors(COLOR.CYAN).helmet,
  },
  [COLOR.BLUE]: {
    body: getColors(COLOR.BLUE).body,
    helmet: getColors(COLOR.BLUE).helmet,
  },
  [COLOR.PINK]: {
    body: getColors(COLOR.PINK).body,
    helmet: getColors(COLOR.PINK).helmet,
  },
  [COLOR.PURPLE]: {
    body: getColors(COLOR.PURPLE).body,
    helmet: getColors(COLOR.PURPLE).helmet,
  },
  [COLOR.GREEN]: {
    body: getColors(COLOR.GREEN).body,
    helmet: getColors(COLOR.GREEN).helmet,
  },
};

/**
 * Возвращает hex-цвет карта
 * @param {COLOR} color
 * @returns {string}
 */
export function getCartColor(color: COLOR) {
  return cartColors[color];
}

/**
 * Возвращает настройки цветов для костюма
 * @param {COLOR} color
 * @returns {string}
 */
export function getCostumeColors(color: COLOR) {
  return costumeColors[color];
}

import React, {memo, useCallback, useEffect, useState} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import View from '../../View';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import {ReactComponent as SVGTimer} from '../../../assets/game-result-timer.svg';
import Div from '../../atomic/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Link from '@vkontakte/vkui/dist/components/Link/Link';

import {formatTime, incline} from '../../../utils/misc';
import {shareResults} from '../../../utils/share';
import useSelector from '../../../hooks/useSelector';
import {useApolloClient, useMutation} from '@apollo/react-hooks';
import useLobbySubscription from '../../../hooks/useLobbySubscription';
import useUserRaceSubscription from '../../../hooks/useUserRaceSubscription';
import bridge from '@vkontakte/vk-bridge';
import {
  GetLobbySharingDataQuery,
  LOBBY_TYPE,
  StartRaceMutation,
  startRaceMutation,
} from 'bridge';

import {isWeb} from '../../../utils/is-web'

import backImageUrl from '../../../assets/game-result-back.base64.svg';
import useActions from '../../../hooks/useActions';
import {layoutActions} from '../../../redux/reducers/layout';
import ViewTitle from '../../ViewTitle'
import { testShare } from './test-share'

interface Props {
  averageSpeed: number;
  correctAnswersCount: number;
  racesCountAvailable: number;
  racesCountThisDay: number;
  time: number;
  lastRaceLobbyId: string | null;
  openedLobbyId: string | null;
  onRaceStarted(): void;
  position: number;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    '& .Panel__in': {
      paddingTop: 50,
      paddingBottom: 60,
    },
  },
  top: {
    background: `url(${backImageUrl}) no-repeat center`,
    backgroundSize: '100% auto',
    marginBottom: 16,
  },
  timerContainer: {
    display: 'table',
    position: 'relative',
    margin: '-20px auto 0',
  },
  timerContent: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: 48,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: 'center',
  },
  timerTitle: {
    fontFamily: theme.typography.fontFamilyTT,
    fontWeight: 600,
    fontSize: 23,
    lineHeight: '26px',
    marginBottom: 10,
  },
  timerInfo: {
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: -0.24,
    fontWeight: 'bold',
  },
  timerNoFuel: {
    margin: '0 auto',
  },
  buttons: {
    marginBottom: 20,
  },
  button: {
    '& + &': {
      marginTop: 10,
    },
  },
  title: {
    fontFamily: theme.typography.fontFamilyTT,
    fontSize: 28,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    lineHeight: '28px',
    marginBottom: 13,
    color: '#0177FD',
  },
  subtitle: {
    fontSize: 15,
    lineHeight: '20px',
    letterSpacing: -0.24,
    textAlign: 'center',
    marginBottom: 16,
    color: '#818C99',
  },
  link: {
    display: 'table',
    margin: 'auto',
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 17,
    lineHeight: '22px',
    letterSpacing: -0.154,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    textAlign: 'center'
  },
  racesExpired: {
    fontSize: 15,
    lineHeight: '18px',
    letterSpacing: -0.24,
    color: '#818C99',
    textAlign: 'center',
  },
}));

const GameResultView = memo((props: Props) => {
  // testShare()

  const {
    averageSpeed, correctAnswersCount, racesCountAvailable, racesCountThisDay,
    time, onRaceStarted, openedLobbyId, lastRaceLobbyId, position,
  } = props;
  const client = useApolloClient();
  const setNavigationShown = useActions(layoutActions.setNavigationShown);
  const {appId, userId} = useSelector(state => ({
    userId: state.config.launchParams.userId,
    appId: state.config.launchParams.appId,
  }));
  const mc = useStyles(props);
  const supportsShare = bridge.supports('VKWebAppShowStoryBox');
  const correctAnswersInclined = incline(
    correctAnswersCount,
    'правильный ответ',
    'правильных ответа',
    'правильных ответов',
  );


  const [sharing, setSharing] = useState(false);
  const onShareClick = useCallback(async () => {
    if (supportsShare) {
      setSharing(true);
      try {
        // В случае, когда эта гонка связана с каким-либо лобби, необходимо
        // получить информацию о том, какая это была гонка и какое место в ней
        // занял пользователь
        if (lastRaceLobbyId) {
          let placement: number | null = null;
          let type: 'semi-final' | 'final' | null = null;

          try {
            const {data} = await client.query<GetLobbySharingDataQuery,
              GetLobbySharingDataQuery.Arguments>({
              query: GetLobbySharingDataQuery,
              variables: {lobbyId: lastRaceLobbyId},
            });

            if (data.lobby) {
              const {leaderboard, type: lobbyType} = data.lobby;
              const sorted = leaderboard.sort((a, b) => {
                return a.time - b.time;
              });
              const userIndex = sorted.findIndex(u => u.userId === userId);

              if (userIndex !== -1) {
                placement = userIndex + 1;
                type = lobbyType === LOBBY_TYPE.SEMI_FINAL
                  ? 'semi-final'
                  : 'final';
              }
            }
          } catch (e) {
            console.error(e)
          }

          if (placement !== null && type) {
            await shareResults({
              time,
              appId,
              type,
              placement,
            });
          }
        }

        await shareResults({
          time,
          appId,
          type: 'common',
        });
      } catch (e) {
        console.error(e)
      }
      setSharing(false);
    }
  }, [supportsShare, appId, time, lastRaceLobbyId, client, userId]);
  const [startRace, srMeta] =
    useMutation<StartRaceMutation>(startRaceMutation);

  useLobbySubscription(openedLobbyId, false, onRaceStarted);
  useUserRaceSubscription(false, onRaceStarted);

  // После того как ответ по запросу начала заезда пришел, уведомляем
  // родительский компонент
  useEffect(() => {
    if (!srMeta.loading && !srMeta.error && srMeta.data) {
      onRaceStarted();
    }
  }, [srMeta.loading, srMeta.error, srMeta.data, onRaceStarted]);

  // When component is mounted, show navigation
  useEffect(() => {
    setNavigationShown(true);
  }, [setNavigationShown]);

  return (
    <View activePanel={'main'} header={false}>
      <Panel id={'main'} className={mc.root}>
        <ViewTitle center={true} className={mc.title}>Спасибо за участие!</ViewTitle>
        <div className={mc.top}>
          <div className={mc.timerContainer}>
            <SVGTimer className={mc.timer}/>
            <div className={mc.timerContent}>
              <div className={mc.timerTitle}>Ваш результат</div>
              <div className={mc.timerInfo}>
                {correctAnswersCount} {correctAnswersInclined}<br/>
                {position} место в рейтинге<br/>
                {formatTime(time)} — время гонки<br/>
                {averageSpeed} км/ч — средняя скорость<br/>
                {/*{racesCountAvailable}/{racesCountThisDay} — доступно
                заездов<br/>на сегодня*/}
              </div>
            </div>
          </div>
        </div>
        {/*racesCountAvailable === 0 &&
        <div className={mc.racesExpired}>
          Вы приняли участие<br/>
          во всех заездах на сегодня.<br/>
          Ждём вас завтра!
        </div>*/}
        <Div className={mc.buttons}>
          {supportsShare &&
          <Button
            className={mc.button}
            size={'xl'}
            onClick={onShareClick}
            disabled={sharing}
          >
            Поделиться результатом
          </Button>}
          {racesCountAvailable > 0 &&
          <Button
            className={mc.button}
            size={'xl'}
            mode={'secondary'}
            onClick={() => startRace()}
          >
            Начать заезд
          </Button>}
        </Div>
        {/*<div className={mc.title}>Спасибо за участие!</div>*/}
        {/*<div className={mc.subtitle}>
          Приходите на стенд VK, чтобы<br/>
          увидеть финал гонок и поучаствовать<br/>
          в других активностях.
        </div>*/}
        {
          isWeb()
            ? <a className={mc.link} href={'https://team.vk.company/vacancy/'}
                 target="_blank"
                 rel="noopener noreferrer">Присоединиться к VK Team</a>
            : <Link
              className={mc.link} href={'https://team.vk.company/vacancy/'}>
              Присоединиться к VK Team
            </Link>
        }

      </Panel>
    </View>
  );
});

export default GameResultView;

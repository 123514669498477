import React, {memo, useCallback, useState} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';

import Insets from './Insets';
import FixedLayout
  from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '../../atomic/Div';
import CarFront from '../../CarFront';
import ColorSelector from '../../ColorSelector';

import {COLOR} from 'bridge';
import {CartRegistrationInfo} from './types';

import imageUrl from '../../../assets/cart-view-background.svg';
import { Theme } from '../../../theme/types'

interface Props {
  onCompleted(cart: CartRegistrationInfo): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    paddingTop: 50,
    paddingBottom: 60,
  },
  mainTitle: {
    fontFamily: theme.typography.fontFamilyTT,
    fontSize: 28,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    lineHeight: '28px',
    marginBottom: 20,
    color: '#0177FD',
  },
  top: {
    position: 'relative',
    background: `url(${imageUrl}) no-repeat center bottom`,
    backgroundSize: '100% auto',
    paddingBottom: '67.47%',
    overflow: 'hidden',
    marginBottom: 20,
  },
  cartInner: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingBottom: 25,
  },
  cart: {
    position: 'absolute',
    maxWidth: '90%',
    animation: '$car-appear 900ms',
    transformOrigin: '50%',
  },
  title: {
    color: 'black',
    fontSize: 15,
    lineHeight: '22px',
    letterSpacing: -0.4,
    marginBottom: 15,
  },
  radio: {
    display: 'flex',
    justifyContent: 'center',
  },
  bottom: {
    height: '60%',
  },
  form: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: `calc(72px + var(--safe-area-inset-bottom))`,
  },
  section: {
    '& + &': {
      marginTop: 25,
    },
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: -5,
    width: 'calc(100% + 10px)',
  },
  gridItem: {
    flex: '1 0 auto',
    padding: 5,
  },
  fixed: {
    paddingTop: 10,
    paddingBottom: 18,
    background: 'linear-gradient(to top, white 0%, #ffffffd9 20%, ' +
      'transparent 100%)',
  },
  '@keyframes car-appear': {
    from: {
      transform: 'scale(0.7)',
    },
    '50%': {
      transform: 'scale(0.7)',
    },
    '90%': {
      transform: 'scale(1.02)',
    },
    to: {
      transform: 'scale(1)',
    },
  },
}));

/**
 * Вью регистрации пользователя
 * @type {React.NamedExoticComponent<object>}
 */
const CartView = memo((props: Props) => {
  const {onCompleted} = props;
  const mc = useStyles(props);
  const [cartColor, setCartColor] = useState(COLOR.CYAN);
  const [costumeColor, setCostumeColor] = useState(COLOR.CYAN);

  const onButtonClick = useCallback(() => {
    onCompleted({cartColor, costumeColor});
  }, [cartColor, costumeColor, onCompleted]);

  return (
    <Insets className={mc.root} bottom={true}>
      <div className={mc.mainTitle}>Соберите свой карт</div>
      <div className={mc.top}>
        <div className={mc.cartInner}>
          <CarFront
            costumeColor={costumeColor}
            cartColor={cartColor}
            className={mc.cart}
          />
        </div>
      </div>
      <div className={mc.bottom}>
        <div className={mc.form}>
          <ColorSelector
            title={'Цвет карта'}
            color={cartColor}
            onChange={setCartColor}
          />
          <ColorSelector
            title={'Цвет гоночного костюма'}
            color={costumeColor}
            onChange={setCostumeColor}
          />
        </div>
      </div>
      <FixedLayout vertical={'bottom'}>
        <Div className={mc.fixed}>
          <Button size={'xl'} onClick={onButtonClick}>Далее</Button>
        </Div>
      </FixedLayout>
    </Insets>
  );
});

export default CartView;

import React, {HTMLProps, memo} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../theme/types';

import Div from './atomic/Div';

interface Props extends HTMLProps<HTMLDivElement> {
  center?: boolean;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamilyTT,
    fontSize: 28,
    lineHeight: '31px',
    textAlign: ({center}) => center ? 'center' : 'left',
  },
}));

/**
 * Заголовок для View
 * @type {React.NamedExoticComponent<Props>}
 */
const ViewTitle = memo((props: Props) => {
  const {className, center, ...rest} = props;
  const mc = useStyles(props);

  return <Div className={c(mc.root, className)} {...rest}/>;
});

export default ViewTitle;

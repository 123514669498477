import React, {memo} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';
import Link from '@vkontakte/vkui/dist/components/Link/Link';
import imageUrl from '../../../assets/onboarding-2.base64.svg';

interface Props {
  onButtonClick(): any;
}

const useStyles = makeStyles<Theme>(theme => ({
  top: {
    position: 'relative',
    flex: '1 0 0',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    left: 0,
    width: '100%',
    bottom: 0,
  },
  bottom: {
    flex: '0 0 auto',
    paddingTop: 45,
    textAlign: 'center',
  },
  text: {
    color: '#818C99',
    letterSpacing: -0.24,
    fontSize: 15,
    lineHeight: '20px',
  },
  link: {
    display: 'table',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    letterSpacing: -.154,
    fontSize: 14,
    lineHeight: '18px',
    margin: '12px auto 47px',
  },
}));

/**
 * Второй слайд
 * @type {React.NamedExoticComponent<Props>}
 */
const SecondSlide = memo((props: Props) => {
  const {onButtonClick} = props;
  const mc = useStyles(props);

  return (
    <>
      <div className={mc.top}>
        <img className={mc.image} src={imageUrl} alt={''}/>
      </div>
      <Div className={mc.bottom}>
        <div className={mc.text}>
          Проверьте свои знания в IT<br/>
          Отвечайте на вопросы<br/>
          Обгоняйте соперников
        </div>
        <Link
          className={mc.link}
          href={'https://m.vk.com/@vkcontest-minirace'}
        >
          Подробные правила игры
        </Link>
        <Button size={'xl'} onClick={onButtonClick}>Далее</Button>
      </Div>
    </>
  );
});

export default SecondSlide;

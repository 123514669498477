import {createContext} from 'react';

export interface EpicContext {
  activeView: string | null;
}

const epicContext = createContext<EpicContext>({
  activeView: null,
});

export default epicContext;

import {useSubscription} from '@apollo/react-hooks';
import {lobbySubscription, LobbySubscription} from 'bridge';
import {useEffect} from 'react';

/**
 * Создает подпсику на лобби и в случае, когда лобби запустилось, вызывает
 * коллбэк
 * @param {string | null} lobbyId
 * @param skip
 * @param onLobbyStarted
 * @returns {number | null}
 */
function useLobbySubscription(
  lobbyId: string | null,
  skip: boolean,
  onLobbyStarted?: () => void,
): number | null {
  const {data} = useSubscription<LobbySubscription,
    LobbySubscription.Arguments>(lobbySubscription, {
    skip,
    variables: {lobbyId: lobbyId || ''},
  });

  // Вызываем коллбэк через время, когда запускается лобби
  useEffect(() => {
    if (data && data.lobby.startsIn !== null && onLobbyStarted) {
      const timeoutId = setTimeout(
        onLobbyStarted, data.lobby.startsIn * 1000,
      );

      return () => clearTimeout(timeoutId);
    }
  }, [data, onLobbyStarted]);

  return data ? data.lobby.startsIn : null
}

export default useLobbySubscription;

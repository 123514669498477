import VKSeparator, {SeparatorProps} from '@vkontakte/vkui/dist/components/Separator/Separator';
import styled from '@material-ui/styles/styled/styled';
import {Theme} from '../../theme/types';

const Separator = styled(VKSeparator)<Theme, SeparatorProps>(({theme}) => ({
  '&.Separator:not(.Separator--wide).Separator--ios .Separator__in': {
    marginLeft: 16,
    marginRight: 16,
    background: 'rgba(0, 0, 0, 0.12)',
  },
}));

export default Separator;

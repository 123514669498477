import {COLOR, FIELD} from 'bridge';

const fieldTitle = {
  [FIELD.ANDROID]: 'Android-разработчик',
  [FIELD.BACKEND]: 'Backend-разработчик',
  [FIELD.DATA_SCIENTIST]: 'Data Scientist',
  [FIELD.DESKTOP]: 'Desktop-разработчик',
  [FIELD.DEVOPS]: 'DevOps-инженер',
  [FIELD.FRONTEND]: 'Frontend-разработчик',
  [FIELD.FULL_STACK]: 'Full-Stack-разработчик',
  [FIELD.IOS]: 'iOS-разработчик',
  [FIELD.ML]: 'ML-разработчик',
  [FIELD.QA]: 'QA-инженер',
  [FIELD.UX_UI]: 'UX/UI-специалист',
  [FIELD.ANALYTIC]: 'Аналитик',
  [FIELD.ARCHITECT]: 'Архитектор ПО',
  [FIELD.GAME_DESIGNER]: 'Гейм-дизайнер',
  [FIELD.DESIGNER]: 'Дизайнер',
  [FIELD.MARKETER]: 'Маркетолог',
  [FIELD.MANAGER]: 'Менеджер',
  [FIELD.SYSTEM_ADMINISTRATOR]: 'Системный администратор',
};

const colorTitle = {
  [COLOR.CYAN]: 'Голубой',
  [COLOR.BLUE]: 'Синий',
  [COLOR.PINK]: 'Розовый',
  [COLOR.PURPLE]: 'Фиолето-вый',
  [COLOR.GREEN]: 'Зелёный',
};

/**
 * Возвращает заголовок для направления
 * @param {FIELD} field
 * @returns {string}
 */
export function getFieldTitle(field: FIELD) {
  return fieldTitle[field];
}

/**
 * Возвращает заголовок для цвета
 * @param {COLOR} color
 * @returns {string}
 */
export function getColorTitle(color: COLOR) {
  return colorTitle[color];
}

import React, {memo, useCallback, useMemo, useState} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Gallery from 'react-swipeable-views';
import FirstSlide from './FirstSlide';
import SecondSlide from './SecondSlide';
import ThirdSlide from './ThirdSlide';

import useInsets from '../../../hooks/useInsets';

import {Insets} from '@vkontakte/vk-bridge';
import useStorageValue from '../../../hooks/useStorageValue';
import {StorageField} from '../../../types/bridge';

const useStyles = makeStyles<Theme, Insets>(() => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: ({bottom}) => bottom,
  },
  inner: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  gallery: {
    flex: '1 0 0',

    '& .react-swipeable-view-container': {
      height: '100%',
    },
  },
  slide: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    height: '100%',
  },
  dots: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
  },
  dot: {
    height: 7,
    width: 7,
    borderRadius: '50%',
    backgroundColor: '#909499',
    opacity: .3,
    transition: '200ms opacity',

    '& + &': {
      marginLeft: 10,
    },
  },
  dotActive: {
    opacity: 1,
  },
}));

/**
 * Вью онбординга
 * @type {React.NamedExoticComponent<object>}
 */
const OnboardingView = memo(() => {
  const insets = useInsets();
  const mc = useStyles(insets);
  const [slide, setSlide] = useState(0);
  const [, setCompleted] = useStorageValue(StorageField.OnboardingCompleted);
  const dots = useMemo(() => new Array(3)
    .fill(null)
    .map((_, idx) => (
      <div className={c(mc.dot, {[mc.dotActive]: slide === idx})} key={idx}/>
    )), [slide, mc.dot, mc.dotActive]);

  const onFirstClick = useCallback(() => setSlide(1), []);
  const onSecondClick = useCallback(() => setSlide(2), []);
  const onStartClick = useCallback(() => setCompleted(true), [setCompleted]);

  return (
    <div className={mc.root}>
      <div className={mc.inner}>
        <Gallery className={mc.gallery} onChangeIndex={setSlide} index={slide}>
          <div className={mc.slide}>
            <FirstSlide onButtonClick={onFirstClick}/>
          </div>
          <div className={mc.slide}>
            <SecondSlide onButtonClick={onSecondClick}/>
          </div>
          <div className={mc.slide}>
            <ThirdSlide onButtonClick={onStartClick}/>
          </div>
        </Gallery>
        <div className={mc.dots}>
          {dots}
        </div>
      </div>
    </div>
  );
});

export default OnboardingView;

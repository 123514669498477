import React, {memo} from 'react';

import OnboardingView from './views/OnboardingView';
import RegistrationView from './views/RegistrationView';

import useStorageValue from '../hooks/useStorageValue';
import useSelector from '../hooks/useSelector';

import {StorageField} from '../types/bridge';
import Views from './Views';

/**
 * Компонент, с которого начинается весь визуал и логика по отображению
 * необходимых элементов.
 * @type {React.NamedExoticComponent<object>}
 */
const App = memo(() => {
  const [onboardingCompleted] =
    useStorageValue(StorageField.OnboardingCompleted);
  const isRegistered = useSelector(state => state.user.isRegistered);

  if (!onboardingCompleted) {
    return <OnboardingView/>;
  }

  if (!isRegistered) {
    return <RegistrationView/>;
  }

  return <Views/>;
});

export default App;
